import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import logo from '../../assets/SvgLogo.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';

import './Footer.css';

export default class FooterTSL extends Component {

    
    render() {
        return (
            <footer className="py-5">
                <Container>
                    <Row>
                        <Col md={8} className="text-center text-md-start mb-4 mb-md-0">
                            <img
                                src={logo}
                                alt="Trygghetsfonden Brand"
                                className="mb-3"
                            />
                            <p>© Trygghetsfonden TSL Copyright. All rights reserved.</p>
                        </Col>

                        <Col className="social-media justify-content-center justify-content-md-end">
                            <a href="https://www.facebook.com/TrygghetsfondenTSL/" target="_blank">
                                <FontAwesomeIcon icon={faFacebookF} />
                            </a>
                            
                            <a href="https://www.linkedin.com/company/trygghetsfonden-tsl/" target="_blank">
                                <FontAwesomeIcon icon={faLinkedinIn} />
                            </a>

                            <a href="https://www.youtube.com/channel/UC2426-Z-2DHM-H4pK22BsTA?" target="_blank">
                                <FontAwesomeIcon icon={faYoutube} />
                            </a>
                        </Col>
                    </Row>
                </Container>
            </footer>
        )
    }
}