import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import logo from '../../assets/SvgLogo.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

import './Navbar.css';

export default class NavbarTSL extends Component {

    
    render() {
        return (
            <Navbar collapseOnSelect expand="lg" className="py-3">
                <Container>
                    <Navbar.Brand href="https://tsl.se/">
                        <img
                            src={logo}
                            alt="Trygghetsfonden Brand"
                        />
                    </Navbar.Brand>
                    
                    {/*<Navbar.Toggle aria-controls="responsive-navbar-nav">
                        <FontAwesomeIcon icon={faBars} />
                        <span>Menu</span>
                    </Navbar.Toggle>

                    <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                        <Nav className="align-items-center py-5 py-lg-0 gap-4">
                            <Nav.Link href="#">Om oss</Nav.Link>
                            <Nav.Link href="#">Press</Nav.Link>
                            <div className="google-translate d-flex flex-column flex-lg-row align-items-md-center gap-2 gap-lg-3">
                                <p className='text-center'>Google Translate</p>
                                <Form.Select>
                                    <option>Välj språk</option>
                                    <option value="1">engelska</option>
                                    <option value="2">finska</option>
                                    <option value="3">norska</option>
                                </Form.Select>
                            </div>
                            <Nav.Link href="#">Sign in</Nav.Link>
                        </Nav>
                    </Navbar.Collapse> */}
                </Container>
            </Navbar>
        )
    }
}