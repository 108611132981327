import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Collapse from 'react-bootstrap/Collapse';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

import './CourseCard.css';

export default function CourseCard(props) {

    const [openCard, setOpenCard] = useState(0);

    return (
        <div className="course-card-style">
            <Row className="info-div justify-content-between">
                <Col fluid="true" className="mb-2 mb-lg-0">
                    <h2 className="mb-2">{props.course}</h2>
                    { props.last_signup_date ? 

                        <div className="d-flex align-items-center gap-2">
                            <div className="status-on"></div>
                            <span>Öppen för anmälan</span><br/>
                        </div>
                    : '' }

                    { props.last_signup_date ? 
                        <div className="d-flex align-items-center gap-2">
                            <div className="status-on"></div>
                            <span>Sista anmälningsdag: {props.last_signup_date }</span>
                        </div>
                    : '' }

                </Col>
                <Col lg="auto">
                    <button type="submit" className="btn-tsl w-100 mb-3" onClick={()=> window.open(props.website, "_blank")}>Till anordnarens webbplats</button>
                    <a
                        onClick={() => setOpenCard(!openCard)}
                        aria-controls="collapse-info"
                        aria-expanded={openCard}
                        className="link-tsl justify-content-end"
                    >
                        Visa {openCard ? ("mindre") :("mer")} {openCard ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
                    </a>
                </Col>
            </Row>

            <Row className="info-div">
                <Col lg={6} className="mb-4 mb-lg-0">
                    <div className="mb-4">
                        <h4>Anordnare</h4>
                        <p>{props.school}</p>
                    </div>

                    <div className="mb-4">
                        <h4>Studieplats</h4>
                        <p>{props.location}</p>
                    </div>

                    <div className="mb-4">
                        <h4>Poäng</h4>
                        <p>{props.points}</p>
                    </div>

                    <div className="mb-4">
                        <h4>Studiestart</h4>
                        <p>{props.start}</p>
                    </div>
                </Col>

                <Col lg={6}>
                    <div className="mb-4">
                        <h4>Undervisningsnivå</h4>
                        <p>{props.type}</p>
                    </div>

                    <div className="mb-4">
                        <h4>Studietakt</h4>
                        <p>{props.pace}</p>
                    </div>

                    <div className="mb-4">
                        <h4>Studieform</h4>
                        <p>{props.form}</p>
                    </div>

                    <div>
                        <h4>Studieslut</h4>
                        <p>{props.end}</p>
                    </div>
                    
                </Col>
            </Row>
      
            <a
                onClick={() => setOpenCard(!openCard)}
                aria-controls="collapse-info"
                aria-expanded={openCard}
                className="link-tsl justify-content-end d-lg-none"
            >
                Visa {openCard ? ("mindre") :("mer")} {openCard ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
            </a>
            
            <Row>
                <Collapse in={openCard}>
                    <div id="collapse-info">
                        <div className="info-div">
                            <h3 className="mb-3">Mer information om utbildningen</h3>
                            <div dangerouslySetInnerHTML={{__html:(props.about)}} />
                            {/*<p>{props.about}</p>*/}
                        </div>

                        <div className="info-div">
                            <h3 className="mb-3">Förkunskapskrav</h3>
                            <p>{props.eligibility}</p>
                            {/*<Row className="mb-4">
                                {props.pre1 ? 
                                    <Col lg={6} className="d-flex gap-2 mb-4">
                                        <FontAwesomeIcon icon={faCheck} />
                                        <p>{props.pre1}</p>
                                    </Col>
                                : <></>}

                                {props.pre2 ? 
                                    <Col lg={6} className="d-flex gap-2 mb-4">
                                        <FontAwesomeIcon icon={faCheck} />
                                        <p>{props.pre2}</p>
                                    </Col>
                                : <></>}

                                {props.pre3 ? 
                                    <Col lg={6} className="d-flex gap-2 mb-4">
                                        <FontAwesomeIcon icon={faCheck} />
                                        <p>{props.pre3}</p>
                                    </Col>
                                : <></>}

                                {props.pre4 ? 
                                    <Col lg={6} className="d-flex gap-2">
                                        <FontAwesomeIcon icon={faCheck} />
                                        <p>{props.pre4}</p>
                                    </Col>
                                : <></>}
                                </Row>*/}
                        </div>

                        <div>
                            <h3 className="mb-3">Kontakt</h3>
                            <Row>
                                {props.name ? 
                                    <Col lg={6} className="mb-4">
                                        <h4>Namn</h4>
                                        <p>{props.name}</p>
                                    </Col> 
                                : <></>}
                                
                                {props.website ? 
                                    <Col lg={6} className="mb-4">
                                        <h4>Webbplats</h4>
                                        <p><a href={props.website} target="_blank">Till utbildningens webbplats</a></p>
                                    </Col>
                                : <></>}
                                
                                {props.address ? 
                                    <Col lg={6} className="mb-4">
                                        <h4>Address</h4>
                                        <p>{props.address}</p>
                                    </Col>
                                : <></>}
                                
                                {props.email ? 
                                    <Col lg={6} className="mb-4">
                                        <h4>E-Post</h4>
                                        <p>{props.email}</p>
                                    </Col>
                                : <></>}
                                
                                {props.phone ? 
                                    <Col lg={6} className="mb-4">
                                        <h4>Telefon</h4>
                                        <p>{props.phone}</p>
                                    </Col>
                                : <></>}
                            </Row>
                        </div>

                        <Row>
                            <a
                                onClick={() => setOpenCard(!openCard)}
                                aria-controls="collapse-info"
                                aria-expanded={openCard}
                                className="link-tsl justify-content-end"
                            >
                                Visa {openCard ? ("mindre") :("mer")} {openCard ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
                            </a>
                        </Row>
                    </div>
                </Collapse>
            </Row>

            
        </div>
    )
}