import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';

import './Search.css';
import './../Filters/Filters.css';

export default class Search extends Component {

    constructor(props) {
        super(props);

        this.options = [
            {
                label: "Start: Våren 2023",
                value: "spring 2023",
            },
            {
                label: "Start: Sommar 2023",
                value: "summer 2023",
            },
            {
                label: "Start: Hösten 2023",
                value: "fall 2023",
            },
            {
                label: "Start: Våren 2024",
                value: "spring 2024",
            },
            {
                label: "Start: Sommar 2024",
                value: "summer 2024",
            },
            {
                label: "Start: Hösten 2024",
                value: "fall 2024",
            },
            {
                label: "Start: Alla tider",
                value: "anytime",
            },
        ];

        this.selectSearchStyle = {
            control: (base, state) => ({ ...base, boxShadow: state.isFocused ? 0 : 0, borderColor: state.isFocused ? "#D3D3D4" : "#D3D3D4", "&:hover": {
              borderColor: state.isFocused ? "#D3D3D4" : "#D3D3D4"}, 
              backgroundColor: '#43474E', minWidth: 245, borderRadius: 1000, fontSize: 16, fontWeight: 500, margin: 0, maxHeight: 42, color: '#fff', border: '2px solid #D3D3D4' }),
            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                //const color = chroma(data.color);
                return {
                    ...styles,
                    backgroundColor: isFocused ? "#1E90FF" : "#43474E",
                };
            },
        };


        this.state = {
            search: '',
            start: this.options[2]
        };
    }

    render() {
        return (
            <Form className="d-flex flex-column flex-md-row align-items-center gap-4">
                <Form.Group controlId="formFilter">
                    {/*<Form.Select onChange={e => this.props.handleChangeStart(e.target.value)}>
                        { this.options.map((option,i) => (
                            <option key={i} value={option.value}>{option.label}</option>
                        )) }
                    </Form.Select> */}
                    <Select value={this.state.start} isSearchable={false} className="advanced-form-select" defaultValue={this.options[0]} styles={this.selectSearchStyle} options={this.options} onChange={e => { this.props.handleChangeStart(e.value); this.setState({ 'start': e }) }}>
                    </Select>
                </Form.Group>

                <Form.Group className="search-group" controlId="formSearch">
                    <Form.Control autoFocus onChange={e => this.props.handleChange(e)} type="text" placeholder="Sök på t ex logistiker" />
                </Form.Group>

                <button onClick={e => this.props.handleSubmit(e)} className="btn-tsl search-btn">Sök</button>
            </Form>
        )
    }
}