// Handle logic for filters

function get_filter_dates(filter_start) {

    if(filter_start == 'spring 2023') {
        return 'startsAfter=20221231&startsBefore=20230801';
    }
    else if(filter_start == 'summer 2023') {
        return 'startsAfter=20230531&startsBefore=20230801';
    }
    else if(filter_start == 'fall 2023') {
        return 'startsAfter=20230731&startsBefore=20240101';
    }
    else if(filter_start == 'spring 2024') {
        return 'startsAfter=20231231&startsBefore=20240801';
    }
    else if(filter_start == 'summer 2024') {
        return 'startsAfter=20240531&startsBefore=20240801';
    }
    else if(filter_start == 'fall 2024') {
        return 'startsAfter=20240731&startsBefore=20250101';
    }
    else { // should not happen
        return '';
    }
}

function ug_get_filter_dates(filter_start) {

    if(filter_start == 'spring 2023') {
        return 'semesterStartFrom=2023-01-01TO2023-05-31';
    }
    else if(filter_start == 'summer 2023') {
        return 'semesterStartFrom=2023-06-01TO2023-07-31';
    }
    else if(filter_start == 'fall 2023') {
        return 'semesterStartFrom=2023-08-01TO2023-12-31';
    }
    else if(filter_start == 'spring 2024') {
        return 'semesterStartFrom=2024-01-01TO2024-05-31';
    }
    else if(filter_start == 'summer 2024') {
        return 'semesterStartFrom=2024-06-01TO2024-07-31';
    }
    else if(filter_start == 'fall 2024') {
        return 'semesterStartFrom=2024-08-01TO2024-12-31';
    }
    else { // should not happen
        return '';
    }
}

// susanavet api expects the codes of municipalities
function get_filter_kommun(filter_kommun) {

    return 'municipality=' + filter_kommun;
}

function get_filter_studieform(filter_studieform) {

    if(filter_studieform=='Distans') {
        return 'distance=true';
    }
    else if(filter_studieform == 'På plats') {
        return 'distance=false'
    }
    else
        return '';
}

function get_filter_studieamne(filter_studieamne) {

    return 'directionIds=' + filter_studieamne;
}

function ug_get_filter_studieamne(filter_studieamne) {

    return 'directionIds=' + filter_studieamne;
}

function   get_filter_studietakt(filter_studietakt) {

    return 'paceOfStudy=' + filter_studietakt;
}

// Reference: https://susanavet2.skolverket.se/api/1.1/reference/organisationForm
// {"folkhögskola":4950,"grundläggande vuxenutbildning":45858,"grundskola":4779,"gymnasial vuxenutbildning":321766,"gymnasieskola":11930,"gymnasiesärskola":718,"högskoleutbildning":165182,"konst- och kulturutbildning":612,"kvalificerad yrkesutbildning":10,"sameskola":6,"sfi":334,"specialskola":11,"särskola":677,"särvux":432,"utbildning med endast tillsyn":168,"vuxenutbildning":28,"yrkeshögskoleutbildning":4330}

function get_filter_anordnare(filter_anordnare) {

    if(filter_anordnare!='') {
        return 'organisationForm=' + filter_anordnare;
    }

    return '';
}

function ug_get_filter_anordnare(filter_anordnare) {

    if(filter_anordnare!='') {
        if(filter_anordnare == 'Folkhögskola')
            return 'typeOfSchool=fhsk';// + filter_anordnare;
        else if(filter_anordnare == 'Högskola och universitet')
            return 'typeOfSchool=forutbildning%2Ccoursebasic%2Ccourseadvanced%2Cprogrambasic%2Cprogramadvanced';
        else if(filter_anordnare == 'Komvux')
            return 'typeOfSchool=komvuxbasiccourses%2Ckomvuxgycourses%2Ckomvuxcoursepackage%2Ckomvuxsfi%2Ckomvuxgrsar%2Ckomvuxgysar';
        else if(filter_anordnare == 'Konst- och kulturutbildningar')
            return 'typeOfSchool=konstkultur';
        else if(filter_anordnare == 'Yrkeshögskola')
            return 'typeOfSchool=yhkurskurspaket%2Cyhprogram';
    
    }

    return '';
}

function get_filter_sprak(filter_sprak) {
    
    return 'language=' + filter_sprak;
}

function ug_get_filter_sprak(filter_sprak) {
    return 'instructionLanguages=' + filter_sprak;
}

// parameters for search api from susanavet 
export function get_params(search_string, filter_start, filter_kommun, 
                    filter_studieform, filter_studieamne, filter_studietakt, filter_anordnare,
                    filter_sprak) {
    
    let params = [];

    if (search_string !== '') {
        params.push('text=' + search_string);
    }

    if(filter_kommun!='') {
        params.push(get_filter_kommun(filter_kommun));
    }

    if(filter_anordnare!='') {
        params.push(get_filter_anordnare(filter_anordnare));
    }

    if(filter_sprak!='') {
        params.push(get_filter_sprak(filter_sprak));
    }

    if(filter_studieform !='') {
        params.push(get_filter_studieform(filter_studieform));
    }

    if(filter_studieamne !='') {
        params.push(get_filter_studieamne(filter_studieamne));
    }

    if(filter_studietakt !='') {
        params.push(get_filter_studietakt(filter_studietakt));
    }

    if (filter_start != '' ) {
        params.push(get_filter_dates(filter_start));
    }

    return params;
}


// parameters for search api from forutbildningsguiden
export function ug_get_params(search_string, filter_start, filter_kommun, 
    filter_studieform, filter_studieamne, filter_studietakt, filter_anordnare,
    filter_sprak) {

    let params = [];

    if (search_string !== '') {
        params.push('searchTerm=' + search_string);
    }

    if(filter_kommun!='' && filter_kommun!='Alla') {
        params.push(get_filter_kommun(filter_kommun));
    }

    if(filter_anordnare!='') {
        params.push(ug_get_filter_anordnare(filter_anordnare));
    }

    if(filter_sprak!='') {
        params.push(ug_get_filter_sprak(filter_sprak));
    }

    if(filter_studieform !='') {
        params.push(get_filter_studieform(filter_studieform));
    }

    if(filter_studieamne !='') {
        params.push(ug_get_filter_studieamne(filter_studieamne));
    }

    if(filter_studietakt !='') {
    params.push(get_filter_studietakt(filter_studietakt));
    }

    if (filter_start != '' && filter_start!='anytime' ) {
    params.push(ug_get_filter_dates(filter_start));
    }

    return params;
}