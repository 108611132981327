import React, { Component } from 'react';

import NavbarTSL from './components/Navbar/Navbar'
import Search from './components/Search/Search'
import { Col, Container, Row } from 'react-bootstrap';
import Filters from './components/Filters/Filters';
import CourseCard from './components/CourseCard/CourseCard';
import FooterTSL from './components/Footer/Footer';

import { get_params } from './filters.js';
import { susanavet_fetch, ug_susanavet_fetch } from './search.js';
import { get_kommun_from_code } from './valuestore.js';

import { get_code_from_utb, fetch_mapping } from './forecasts.js';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

//this const needs to change to 'true' when the server returns the search results
let isSearched = false;

//this const needs to change to 'true' when the server returns more than 20 search results
let isMoreThan20 = false;
let showMoreButton = false;
let totalElements = 0;
let currentElements = 0;
let currentPage = 0;
const elementsPerPage = 20;

//this const needs to change to 'true' when the server returns no search results
let noResults = false;

export default class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      search: '',
      result_content: [],
      result_info: { totalElements: 0 },
      filter_start: 'fall 2023',
      filter_kommun: '',
      filter_studieform: '',
      filter_studieamne: '',
      filter_studietakt: '',
      filter_anordnare: '',
      filter_sprak: '',
      course_cards: []
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.handleChangeFilter = this.handleChangeFilter.bind(this);
    this.fetchNextPage = this.fetchNextPage.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
  }

  async resetFilters() {
    
    this.setState( {
      //filter_start: 'fall 2023',
      filter_kommun: '',
      filter_studieform: '',
      filter_studieamne: '',
      filter_studietakt: '',
      filter_anordnare: '',
      filter_sprak: ''
    }, async function() { await this.update_data() })
  }

  generate_course_cards(content, page_info) {
    
    let course_cards = [];

    if(content.length > 0){
      for(let i=0;i<content.length;i++) {
        const item = content[i];

        const course = item.info.title.string[0].content;
        const school = item.provider && item.provider.name.string ? item.provider.name.string[0].content : '-';
        
        let location = '-';
        if(item.event.location) {
          if('town' in item.event.location[0]) {
            location = item.event.location[0].town;
          }
          else if('municipalityCode' in item.event.location[0]) {
            location = get_kommun_from_code(item.event.location[0]['municipalityCode'])
          }
        }

        const type = item.info.form ? item.info.form.code : '-';
        
        let start = item.event.execution.start? item.event.execution.start: '-';
        start = start.length>10? start.substring(0,10): start;

        let end = item.event.execution.end? item.event.execution.end: '-';
        end =  end.length>10? end.substring(0,10): end;
        
        const form = item.event.distance? 'Distans': 'På plats';
        const pace = item.event.paceOfStudy ? item.event.paceOfStudy.percentage + '%' : '-';
        let last_signup_date = item.event.application? item.event.application.last: null; //item.event.extension && item.event.extension[0].applicationDetails && item.event.extension[0].applicationDetails[0].closeDate ?  item.event.extension[0].applicationDetails[0].closeDate[0] : '';

        if(last_signup_date && last_signup_date.length>10) {
          last_signup_date = last_signup_date.substring(0,10);
        }

        if(last_signup_date) { // exclude if before today
          const date_signup = Date.parse(last_signup_date);
          const today = new Date();
          if(date_signup<today) {
            last_signup_date = null;
          }
        }

        let points = item.info.credits ? item.info.credits.credits[0] : '-';
        points += item.info.credits ? ' ' + item.info.credits.system[0].code : '';

        const about = item.info.description? item.info.description.string[0].content: '-';

        const eligibility = item.info.eligibility && item.info.eligibility.eligibilityDescription ? item.info.eligibility.eligibilityDescription[0].string[0].content : '-';

        if(item.info.eligibility && !item.info.eligibility.eligibilityDescription){
          console.log('Warning: eligibility:' + item.info.eligibility)          
        }

        const name= school; //old responsible body: item.provider.responsibleBody ? item.provider.responsibleBody.name.string[0].content + ' (' + item.provider.responsibleBody.type.code + ')' : '';
        const website= item.event.url ? item.event.url.url[0].content : '-'; 
        
        let address = '';
        if(item.provider && item.provider.visitAddress) {
          address = item.provider.visitAddress.streetAddress + ', ';
          if (item.provider.visitAddress.postCode) {
            address += item.provider.visitAddress.postCode + ' ';
          }

          address += item.provider.visitAddress.town;
        }

        const email= item.provider? item.provider.emailAddress: '-';
        const phone= item.provider && item.provider.phone ? item.provider.phone[0].number : '-';

        let c = { course: course, school: school, location: location, type: type, start: start, end: end, last_signup_date: last_signup_date, 
                  form: form, pace: pace, points: points, about: about, eligibility: eligibility, name: name,
                  website: website, address: address, email: email, phone: phone }

        course_cards.push(c);

      // Not used atm:
      // Eligible for student aid: item.info.eligibleForStudentAid ? 'Student aid: ' + item.info.eligibleForStudentAid.code : ''
      }
    }

    return course_cards;
  }

  reset_data() {

    isMoreThan20 = false;
    noResults = false;
    totalElements = 0;
    currentElements = 0;
    currentPage = 0;

    const [content, page_info] = [0, 0];
    
    const course_cards = this.generate_course_cards(content, page_info);
    this.setState({ result_content: content })
    this.setState({ result_info: page_info })
    this.setState({ course_cards: course_cards })
  }

  async update_data(page = 0) {

    const search_string = this.state['search'];
    const filter_start = this.state['filter_start'];
    const filter_kommun = this.state['filter_kommun'];
    const filter_studieform = this.state['filter_studieform'];
    const filter_studieamne = this.state['filter_studieamne'];
    const filter_studietakt = this.state['filter_studietakt'];
    const filter_sprak = this.state['filter_sprak'];
    const filter_anordnare = this.state['filter_anordnare'];

    const [content, page_info] = await ug_susanavet_fetch(page, search_string, filter_start, filter_kommun, filter_studieform, filter_studieamne, filter_studietakt, filter_sprak, filter_anordnare);

    // NOT USED: 
    // Move this to course card expand
    /*
    for(let i=0;i<content.length;i++) {

        let code = get_code_from_utb(content[i]);
        console.log(code)
        let utbildningsgrupp = fetch_mapping(code);

        console.log(code + ': ' + utbildningsgrupp);
    }
    */
    // end

    if (page_info.totalElements > elementsPerPage){
      noResults = false;
      isMoreThan20 = true;
      showMoreButton = true;
      totalElements = page_info.totalElements;
      currentElements = page_info.number*elementsPerPage + elementsPerPage;
    } else if (page_info.totalElements == 0){
      noResults = true;
    }
    else {
      isMoreThan20 = false;
      noResults = false;
    }

    if(currentElements>totalElements) {
      currentElements = totalElements;
      showMoreButton = false;
    }

    const course_cards = this.generate_course_cards(content, page_info);

    if(page == 0) {
      this.setState({ result_content: content })
      this.setState({ result_info: page_info })
      this.setState({ course_cards: course_cards })
    }
    else { // append
      this.state['course_cards'].push(...course_cards);
      this.setState( { course_cards: this.state['course_cards'] });
    }
  }

  async fetchNextPage(e) { 
    e.preventDefault()
    currentPage++;
    await this.update_data(currentPage);
  }

  async handleChange(event) {
    this.setState({ search: event.target.value });
    //await this.update_data();
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.reset_data();
    await this.update_data();
    isSearched = true;
  }



  async handleChangeFilter(event, filter_name) {
    
    let d = {}

    if('value' in event) { // event from react-select component
      d[filter_name] = event.value;
    }
    else if('target' in event) // standard event
      d[filter_name] = event.target.value;
    else {
      console.log('Error: Could not interpret event.')
    }

    await this.setState(d);
    await this.update_data();
  }

  async handleChangeStart(start_value) {
    await this.setState({ filter_start: start_value })//event.target.value });
    await this.update_data();
  }

  render() {
    return (
      <div className="App">
        <div className="full-screen">
          <NavbarTSL/>

          <section className="hero">
            <Container>
              <Row>
                <Col md={{ span: 8, offset: 2 }} className="hero-content text-center d-flex flex-column">
                  <h1>Välkommen till Utbildningskatalogen</h1>
                  <p className="preamble">
                      Hitta din utbildning
                  </p>
                  <Search start={this.state['filter_start']} handleChange={this.handleChange} handleSubmit={this.handleSubmit} handleChangeStart={this.handleChangeStart}/>
                </Col>
              </Row>
            </Container>
          </section>

          {isSearched ?
            <section id="searchResult">
              <Container>
                <Row className="gap-lg-4 gap-md-3 gap-5">
                  <Col md={4} lg={3}>
                    <Filters totalElements={this.state.result_info.totalElements} handleChangeFilter={this.handleChangeFilter} resetFilters={this.resetFilters}/>
                  </Col>

                  <Col className="courses">
                    { this.state.course_cards.map((c, i) => 
                      <CourseCard key={i}
                        course={ c.course }
                        school={ c.school } 
                        location = { c.location }
                        type={ c.type }
                        start={ c.start } 
                        end = { c.end }
                        last_signup_date = { c.last_signup_date }
                        form = { c.form }
                        pace = { c.pace }
                        points={ c.points }
                        about={ c.about }
                        eligibility= { c.eligibility } 
                        pre1={ c.pre1 } 
                        pre2={ c.pre2 }
                        pre3={ c.pre3 } 
                        pre4={ c.pre4 } 
                        name={ c.name }
                        website={ c.website } 
                        address={ c.address } 
                        email={ c.email } 
                        phone={ c.phone }/>
                    ) }
                    
                    {noResults ?
                      <span className="text-center">Inga resultat hittades för denna sökning</span>
                    : <></>}

                    {isMoreThan20 && !noResults ?
                      <div className="load-more">
                        <span>{currentElements} av {totalElements} träffar</span>
                        {showMoreButton? <a className="link-tsl" onClick={this.fetchNextPage}>Visa nästa {elementsPerPage} sökträffar</a>: <></>}
                      </div>
                    : <></>}
                  </Col>
                </Row>
              </Container>
            </section>
          : <></>}
        </div>

        <FooterTSL/>
      </div>
    );
  }
}